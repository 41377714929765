import React from 'react';
import square_dots_BG from '../../assets/subscriptions/square_dots_BG.svg';
import checkmark from '../../assets/subscriptions/checkmark_icon.svg';
// import arrow_down from '../../assets/subscriptions/arrowdown_expand.svg';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import useCheckHostname from '../../utility/helper';

const ParentCards = () => {
  const { t } = useTranslation('common');
  // const packagePrice = useCheckHostname('education') ? 3.99 : 1.99;
  // const currency = '€';
  // const navigate = useNavigate();

  const [card1Open] = useState(false);
  const [card2Open] = useState(false);

  return (
    <div className="packages__cards">
      <div
        className={`packages__card ${
          card1Open ? '' : 'packages__card--closed-350'
        } packages__card--light--green parents__card--1`}
      >
        <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
        <h3> {t('packages.parent.0.name')}</h3>
        <p className="txt-center"> {t('packages.parent.0.type')}</p>
        {/* <h4>
          <span>
            {currency}
            {packagePrice}
          </span>
          <br />
          <div className="vat-included--2">
            <p>{t('packages.vatIncluded')}</p>
          </div>
          {t('packages.parent.0.billingType')}
        </h4> */}
        <br />
        <h5> {t('packages.parent.0.benefitsTitle')}</h5>
        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.parent.0.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.parent.0.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>
        {/* <div className="packages__card__bottom">
          <div className="packages__card__bottom__gradient"></div>
          <div className="packages__card__separator packages__card__bottom__separator"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard1Open(true)}
          />
          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=parent&package=0`)
            }
          >
            {t('packages.parent.0.btn')}
          </button>
        </div> */}
      </div>
      <div
        className={`packages__card ${
          card2Open ? '' : 'packages__card--closed-350'
        } packages__card--dark--green parents__card--2`}
      >
        <h3>{t('packages.parent.1.name')}</h3>
        {/* <h4>
          <span>{t('packages.parent.1.price')}</span>
        </h4>
        <h5>{t('packages.parent.1.billingType')}</h5>
        <div className="packages__card__separator"></div> */}
        <br />
        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.parent.1.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.parent.1.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>
        {/* <div className="packages__card__bottom">
          <div className="packages__card__separator packages__card__bottom__separator "></div>
          <div className="packages__card__bottom__gradient"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard2Open(true)}
          />
          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=parent&package=1`)
            }
          >
            {t('packages.parent.1.btn')}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ParentCards;
