import React from 'react';
import square_dots_BG from '../../assets/subscriptions/square_dots_BG.svg';
import checkmark from '../../assets/subscriptions/checkmark_icon.svg';
// import arrow_down from '../../assets/subscriptions/arrowdown_expand.svg';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import useCheckHostname from '../../utility/helper';
const SchoolCards = () => {
  const { t } = useTranslation('common');
  // const [activePriceIndex, setActivePriceIndex] = useState(0);
  // const pricePackage1 = useCheckHostname('education') ? 3.99 : 1.99;
  // const pricesPackage2 = [199, 299, 499];
  // const currency = '€';
  // const navigate = useNavigate();

  const [card1Open] = useState(false);
  const [card2Open] = useState(false);

  return (
    <div className="packages__cards">
      <div
        className={`packages__card ${
          card1Open ? '' : 'packages__card--closed-350'
        } packages__card--light--purple schools__card--1`}
      >
        <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
        <h3>{t('packages.school.0.name')}</h3>
        <p className="txt-center">{t('packages.school.0.type')}</p>
        {/* <h4>
          <span>
            {currency}
            {pricePackage1}
          </span>
          <div className="vat-included--1">
            <p>{t('packages.vatIncluded')}</p>
          </div>
          <br /> {t('packages.school.0.billingType2')}
        </h4> */}
        <br />
        <h5>{t('packages.school.0.benefitsTitle')}</h5>

        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.school.0.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.school.0.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>

        {/* <div className="packages__card__bottom">
          <div className="packages__card__bottom__gradient"></div>
          <div className="packages__card__separator packages__card__bottom__separator"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard1Open(true)}
          />
          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=school&package=0`)
            }
          >
            {t('packages.school.0.btn')}
          </button>
        </div> */}
      </div>
      <div
        className={`packages__card  ${
          card2Open ? '' : 'packages__card--closed-350'
        } packages__card--dark--purple schools__card--2`}
      >
        <h3>{t('packages.school.1.name')}</h3>
        <p className="txt-center">{t('packages.school.1.type')}</p>
        {/* <h4>
          <span>
            {currency}
            {pricesPackage2[activePriceIndex]}
            <br />
          </span>
          <div className="vat-included--2">
            <p>{t('packages.vatIncluded')}</p>
          </div>
          {t('packages.school.1.billingType2')}
        </h4> */}
        {/* <div className="packages__card__amount__container">
          <p>{t('packages.school.1.rangeLabel')}</p>
          <div className="packages__card__amount__selectors">
            <div
              className={
                'packages__card__amount__selector ' +
                (activePriceIndex === 0 ? 'packages__card__amount__selector--active' : '')
              }
              onClick={() => setActivePriceIndex(0)}
            >
              <p>&lt;400</p>
            </div>
            <div
              className={
                'packages__card__amount__selector ' +
                (activePriceIndex === 1 ? 'packages__card__amount__selector--active' : '')
              }
              onClick={() => setActivePriceIndex(1)}
            >
              <p>&lt;800</p>
            </div>
            <div
              className={
                'packages__card__amount__selector ' +
                (activePriceIndex === 2 ? 'packages__card__amount__selector--active' : '')
              }
              onClick={() => setActivePriceIndex(2)}
            >
              <p>801+</p>
            </div>
          </div>
        </div> */}
        <br />
        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.school.1.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.school.1.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>

        {/* <div className="packages__card__bottom">
          <div className="packages__card__separator packages__card__bottom__separator "></div>
          <div className="packages__card__bottom__gradient"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard2Open(true)}
          />

          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=school&package=1`)
            }
          >
            {t('packages.school.1.btn')}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default SchoolCards;
