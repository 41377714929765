import React from 'react';

import './RoTermsConditions.css';

const RoTermsConditions = () => {
  const currentHost = window.location.host;

  return (
    <div className="comp-ro-terms-conditons">
      <h3>Termeni și condiții</h3>
      <h4>Bun venit la Adservio</h4>
      <p>
        Vă mulțumim pentru utilizarea produselor și a serviciilor noastre (&quot;Serviciile&quot;).
        Serviciile sunt oferite de companiile Adservio Social Innovation și Adservio Center cu
        sediile în municipiul Iaşi, Bulevardul SOCOLA, Nr. 3, Bloc D2, Scara B, Etaj 4, Ap. 19,
        județul Iaşi, respectiv în municipiul Iași, Str. HANCIUC, Nr. 15, Bloc 356, Scara B, Etaj 2,
        Ap. 8, Camera 1, județul Iași, în continuare denumite &quot;Adservio&quot;.
      </p>
      <p>
        Prin utilizarea Serviciilor noastre, sunteți de acord cu prezenții Termeni și condiții. Vă
        rugăm să îi citiți cu atenție.
      </p>
      <p>
        Serviciile noastre sunt dedicate mediului educațional, ce implică și prelucrarea de date ale
        minorilor și, prin urmare, este posibil ca uneori să se aplice Termeni și condiții
        suplimentare sau cerințe suplimentare pentru produse, ce vor fi aduse la cunoștință în mod
        prompt înainte de accesarea acestor produse. În cazul anumitor Servicii, care vor fi expres
        menționate de către Adservio, pot exista Termeni și condiții suplimentare, iar, dacă
        utilizați acele Servicii, Termenii și condițiile suplimentare respective s-ar putea să facă
        parte din contractul între unitatea școlară din care face parte copilul dumneavoastra și
        Adservio sau să fie publicate pe website-ul Adservio -{' '}
        <a href={`https://${currentHost}/`}>{currentHost}</a>
      </p>
      <h4>Utilizarea Serviciilor noastre</h4>
      <p>Trebuie să respectați toate politicile puse la dispoziție în cadrul Serviciilor.</p>
      <p>
        Utilizarea Serviciilor nu vă oferă niciun drept de proprietate intelectuală asupra acestora
        sau asupra conținutului pe care îl accesați. Dumneavoastră ca utilizatori Adservio,
        accesați/achiziționați servicii de informare, servicii de management școlar informațional,
        servicii de dotare cu echipamente și internet sau servicii de asistență. Nu aveți dreptul să
        utilizați conținutul din cadrul Serviciilor noastre, cu excepția cazului în care prin
        contractarea de către unitatea școlară a licenței asupra sistemului Adservio, deveniți
        utilizatori ai serviciilor noastre.
      </p>
      <p>
        Serviciile noastre afișează un anumit conținut care nu aparține Adservio. Responsabilitatea
        pentru acest conținut revine exclusiv entității care îl pune la dispoziție și anume unitații
        școlare precum și utilizatorilor, în ceea ce privește modulul de mesagerie, iar acceptând să
        utilizați Serviciile vă asumați ca prin conținutul pe care îl generați să nu încălcați
        drepturile altor persoane, să nu faceți remarci xenofobe, rasiste, sau care instigă la ură
        și să nu realizați orice alt conținut ofensator sau denigrator. În ceea ce privește
        utilizarea Serviciilor de către dvs., putem să vă trimitem anunțuri privind serviciile,
        mesaje administrative și alte informații. Puteți renunța la unele dintre aceste comunicări
        sau la toate, prin bifarea/debifarea acestora din Setările contului dumneavoastra Adservio.
      </p>
      <p>
        La momentul începerii utilizării serviciilor Adservio puteți opta să fiți de acord sau nu ca
        Adservio să realizeze sondaje de opinie, campanii de fiidelizare și campanii de promovare în
        rândul utilizatorilor folosind ca mijloace de comunicare contul de utilizator, adresa de
        email sau numărul de telefon, dezabonarea putând fi solicitată oricând la adresa de e-mail:{' '}
        <a href={`mailTo:relatiiclienti@${currentHost}`}>relatiiclienti@adservio.ro</a>.
      </p>
      <h4>Contul dvs. Adservio</h4>
      <p>
        Pentru a utiliza serviciile Adservio, este necesar un Cont Adservio. Prin contractarea
        sistemului Adservio de către unitatea şcolară, deveniți una din următoarele categorii de
        utilizatori: cadru didactic, personal secretariat, personal conducere, părinte sau elev.
      </p>
      <p>
        Politicile de confidențialitate Adservio explică modul în care tratăm datele dvs. cu
        caracter personal și în care vă protejăm confidențialitatea atunci când utilizați Serviciile
        noastre și pot fi accesate pe website-ul{' '}
        <a href={`https://${currentHost}/`}>{currentHost}</a>.
      </p>
      <h4>Conținutul dvs. în cadrul Serviciilor noastre</h4>
      <p>
        Anumite Servicii vă permit să trimiteți conținut. Adservio administrează platforma de
        management școlar interactiv și modulele integrate în această platformă: catalog școlar
        electronic, mesagerie, modului dedicat secretariatului, modulul dedicat directorilor,
        modulul pentru profesor și nu intervine asupra datelor conținute de sistem.
      </p>
      <p>
        Anumite Servicii vă pot oferi modalități de accesare și de eliminare a conținutului furnizat
        către respectivul Serviciu. De asemenea, anumite Servicii au Termeni și condiții sau setări
        care restrâng domeniul de utilizare a conținutului trimis în cadrul respectivelor Servicii.
        Asigurați-vă că dețineți drepturile necesare pentru a putea accesa modulele corespunzătoare
        pentru întregul conținut pe care îl trimiteți către Serviciile noastre.
      </p>
      <p>
        Puteți afla mai multe informații despre modul în care Adservio utilizează şi stochează
        conținutul în politica de confidențialitate sau în Termenii și condițiile suplimentare
        pentru anumite Servicii care vor fi puse la dispoziția dumneavoastră pe website-ul{' '}
        <a href={`https://${currentHost}/`}>{currentHost}</a>. Dacă trimiteți feedback sau sugestii
        despre Servicii, putem utiliza feedbackul sau sugestiile respective fără a se încălca
        legislația privind protecția datelor cu caracter personal.
      </p>
      <h4>Despre programele software din cadrul Serviciilor</h4>
      <p>
        Nu aveți dreptul să copiați, să modificați, să distribuiți, să vindeți sau să închiriați
        nicio parte a Serviciilor și a programelor software incluse; nu aveți dreptul să refaceți,
        sa aplicați ingineria inversă sau să încercați să extrageți codul sursă al respectivului
        program software, cu excepția cazurilor în care aveți permisiunea scrisă din partea noastră.
      </p>
      <h4>Perioada de probă „Adservio Premium”</h4>
      <p>
        Serviciul „Adservio Premium” este disponibil utilizatorilor „Adservio Basic” de tip părinți
        sau copii care aleg să achiziționeze un plan de abonament anual „Adservio Premium” sau care
        optează pentru o perioadă de probă.
      </p>
      <p>
        Perioadă de probă pentru pachetul „Adservio Premium” este gratuită și durează 30 de zile
        calendaristice. În timpul acestei perioade, veți putea folosi pachetul „Adservio Premium” și
        aveți dreptul să anulați pachetul „Adservio Premium” fără nicio obligație financiară.
        Utilizarea pachetului „Adservio Premium” în perioada de probă implică o pre-autorizare a
        cardului bancar introdus în sistemul Adservio. La sfârșitul celor 30 de zile calendaristice
        și doar în măsura în care nu veți renunța la acest serviciu până la sfârșitul perioadei de
        probă, cardul dvs. va fi debitat cu suma aferentă abonamentului anual „Adservio Premium”
      </p>
      <h4>Facturare și Plată „Adservio Premium”</h4>
      <p>
        Prețul abonamentului „Adservio Premium” va fi prevăzut pe website-ul www.adservio.ro. Prețul
        va fi indicat în Euro, iar plata se va face la contravaloarea acestuia în lei la cursul de
        schimb BNR din ziua emiterii facturii. Pretul pachetului „Adservio Premium” va rămâne
        același indiferent de momentul abonării in cursul Perioadei anuale de abonament, urmând ca
        perioadă de abonament rămasă să fie cea de la momentul abonării și până la expirarea
        Perioadei anuale de abonament.
      </p>
      <p>
        Plata abonamentului anual se poate face prin mijloacele electronice indicate în sistemul
        Adservio.
      </p>
      <p>
        În măsura în care plata a fost efectuată printr-un card bancar, abonamentul va fi reinnoit
        automat la sfârșitul fiecărei perioade de abonament prin debitarea aceluiași card folosit
        anterior.
      </p>
      <p>
        Perioada de abonament începe de la momentul efectuării plății și se termină în ziua
        anterioară începerii unui nou an școlar față de anul școlar în care a început perioada de
        abonament („<strong>Perioada anuala de abonament</strong>”). Perioada anuală de abonament se
        raportează la anul școlar din unitatea școlară în care este înscris elevul. Dacă nu ați
        renunțat la serviciul „Adservio Premium” până la expirarea Perioadei anuale de abonament în
        curs, se va presupune că ați autorizat Adservio să perceapă prețul pentru o nouă perioadă de
        abonament prin debitarea aceluiași card folosit anterior sau a ultimului card autorizat în
        sistem.
      </p>
      <p>
        Renunțarea la reînnoirea automată a abonamentului anual aferent serviciului „Adservio
        Premium” se poate efectua până la sfârșitul fiecărei Perioade anuale de abonament, prin
        renunțarea la serviciul „Adservio Premium” în aplicația Adservio sau prin informarea
        Adservio în legătură cu intenția dumneavoastră. În măsura în care veți renunța la serviciul
        „Adservio Premium” sau nu se va putea realiza plata unei noi perioade anuale de abonament,
        veți avea acces în continuare la serviciul „Adservio Start”/ „Adservio Esential” în mod
        gratuit, pe întreaga perioadă în care există un parteneriat între Adservio și unitatea
        școlară în care este înscris elevul.
      </p>
      <p>
        În măsura în care va fi cazul, Adservio va putea efectua modificări ale prețului
        abonamentului. Orice creștere se va aplica doar în ciclul de facturare următor. Orice astfel
        de modificare vă va fi notificată prin e-mail cu cel puțin 30 de zile înainte ca modificarea
        să intre în vigoare. Continuarea utilizării Serviciilor după intrarea în vigoare a unei
        modificări a prețului echivalează cu acordul dvs. cu privire la suma modificată.
      </p>
      <h4>Modificarea și încetarea furnizării Serviciilor</h4>
      <p>
        Modificăm și îmbunătățim în mod constant Serviciile. Putem să adăugăm sau să eliminăm
        funcționalități ori funcții, iar aceste modificări vor fi notificate în prealabil astfel
        încat furnizarea de servicii către dumneavoastră să nu sufere.
      </p>
      <h4>Garanții și declinarea responsabilității</h4>
      <p>
        Furnizăm Serviciile la un nivel de aptitudine și atenție rezonabil din punct de vedere
        comercial, cu respectarea securității, integrității și confidențialității datelor și sperăm
        că vă va face plăcere să le utilizați. Dar, există anumite lucruri pe care nu le putem
        promite în legătură cu Serviciile. Unitatea școlară este responsabilă de actualitatea și
        integralitatea datelor cu privire la absențe, note discilpine/materii predate din sistem.
        Pentru claritate, Adservio nu are dreptul de a interveni în niciun fel asupra datelor din
        sistem furnizate către unitatea școlară, cu excepția cazurilor în care Adservio se angajează
        în mod explicit să realizeze aceasta acțiune prin contract, cu respectarea prevederilor
        legale. În orice altă situație, Aservio nu este responsabil de lipsa datelor din sistem ce
        ar trebui să fie furnizate de către unitatea școlară sau întârzieri privind completarea
        datelor de către unitatea școlară.
      </p>
      <h4>Răspunderea pentru Serviciile noastre</h4>
      <p>
        Adservio nu va fi în niciun caz respnsabil pentru nicio pierdere sau pagubă care intră în
        categoria forței majore.
      </p>
      <h4>Despre prezenții Termeni și condiții</h4>
      <p>
        Putem să modificăm prezenții Termeni și Condiții și Termenii și Condițiile suplimentare care
        se aplică unui Serviciu, de exemplu, pentru a corespunde schimbărilor survenite la nivelul
        legislației sau al Serviciilor noastre. Consultați periodic Termenii și condițiile.
        <br /> Pe această pagină vom posta notificările privind modificările prezenţilor Termeni şi
        Condiţii. Vom posta notificările cu privire la modificarea Termenilor și condițiilor
        suplimentare în cadrul Serviciului în cauză. Modificările nu se vor aplica retroactiv și vor
        intra în vigoare în termen de minimum paisprezece zile după postare. Totuși, modificările
        aferente funcțiilor noi ale unui Serviciu și cele din motive legale vor intra în vigoare
        imediat. Dacă nu sunteți de acord cu modificările aduse Termenilor și Condiţiilor aferente
        unui Serviciu, vă sugerăm să întrerupeți utilizarea Serviciului respectiv.
      </p>
      <ul>
        <li>
          În cazul unei discrepanțe între prezenții Termeni și Condiții și Termenii și condițiile
          suplimentare, vor prevala Termenii și condiţiile suplimentare.
        </li>
        <li>
          Prezenții Termeni și Condiții reglementează relația dintre Adservio și dvs. Aceștia nu
          creează niciun fel de drepturi pentru terți beneficiari.
        </li>
        <li>
          Dacă nu respectați prezenții Termeni și Condiții și nu luăm măsuri imediate, nu înseamnă
          că renunțăm la drepturile pe care le avem (de exemplu, dreptul de a lua măsuri pe viitor).
        </li>
        <li>
          Dacă anumiți termeni și anumite condiții se dovedesc a fi inaplicabili, aceasta nu va
          afecta restul Termenilor și Condițiilor.
        </li>
      </ul>
      <p>
        Pentru informații despre modul în care puteți să contactați Adservio, accesați pagina
        noastră de contact.
      </p>
    </div>
  );
};

export default RoTermsConditions;
